@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": #0074d9,
  "secondary": #6c757d,
  "success": #28a745,
  "danger": #ff4136,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #f8f9fa,
  "dark": #343a40,
  "white": #ffffff,
  "transparent": transparent,
);

// TEXT
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

body {
  //background-color: #ead502;
  background-color: #fcda05;
}

.font-size-sm {
  font-size: small;
  color: #1d1a0f;
  font-weight: bold;
  font-style: italic;
}

.fa-copy:hover {
  cursor: pointer;
  height: 12px !important;
}

.code-mirror {
  text-align: left !important;
}

.ReactModal__Overlay--after-open {
  z-index: 4;
}

.ReactModal__Content {
  border-radius: 15px !important;
  box-shadow: #1d1a0f !important;
}

.text-green {
  color: #28a745;
}

.url-container {
  border: #1d1a0f !important;
  padding: 2px !important;
  padding-left: 4px !important;
}
.fa-copy:focus {
  color: green;
}
.fa-copy {
  font-size: x-large;
  color: #434b53;
  align-self: center;
}
.text-pink {
  color: rgb(233, 67, 248);
}

@media (min-width: 768px) {
  .navlogo {
    height: 50px;
    /*border-color: red;
        border-width: 5px;
        border-style: solid;*/
  }
  .feature-image {
    display: block;
  }
}
@media (max-width: 768px) {
  .feature-image {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .ReactModal__Content {
    width: 400px !important;
  }
}

@media only screen and (min-width: 768px) {
  .ReactModal__Content {
    width: auto !important;
  }
}

.word-break {
  word-break: break-all;
}
